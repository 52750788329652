
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import { analyticsOutline, pricetagOutline, informationOutline, trashOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon
  },
  setup(){
    return{analyticsOutline,pricetagOutline, informationOutline, trashOutline}
  }
});
